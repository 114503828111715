import { 
	SectionIntro,
	SectionAbout,
	SectionWhatWeOffer,
	SectionJoin,
	SectionDiscover,
	SectionPricing,
	SectionCommunity,
	SectionSocial,
    SectionLocations,
} from './templates'

export const resolveSection = (layout) => {
    let section;

    // Map layout strings to blocks

    const sections = {
        'intro': SectionIntro,
        'about': SectionAbout,
        'what-we-offer': SectionWhatWeOffer,
        'join': SectionJoin,
        'discover': SectionDiscover,
        'pricing': SectionPricing,
        'community': SectionCommunity,
        'social': SectionSocial,
        'locations': SectionLocations,
    }
    
    return sections[layout]
}


