import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Block } from '../../../components'

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const Section = (props) => {
	const { title, image, text, useLink, links } = props;

	// Links

	const renderLinks = () => {
		const items = links.map((item, i) => {
			return (
				<Block key={i} layout={'link'} type={item.type} link={item.link} display />
			)
		})

		return (
			<List>{items}</List>
		)
	}
        
	return (
		<Container>
			<Wrap>
				<Title dangerouslySetInnerHTML={{__html: title}} />
				<Text dangerouslySetInnerHTML={{__html: text}} />

				{useLink && (
					renderLinks()
				)}
			</Wrap>
			<Wrap>
				{image && (
					<Image
						key={image?.sizes?.full_width}
						src={image?.sizes?.full_width}
					>
						{(src, loading) => {
							return (
								<BGImage
									image={src}
									style={{ opacity: loading ? 0 : 1 }}
								/>
							)
						}}
					</Image>
				)}
			</Wrap>
		</Container>
	)
}

// Shared

const Wrap = styled.div``
const Title = styled.div``
const Text = styled.div``
const Button = styled.div``
const List = styled.div``

// Utility 

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgIcon};
    transition: opacity 0.45s ease;
`

// Wrapper

Section.wrapper = css`
	margin-bottom: 0;
`

// Layout

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 80px 150px;
	box-sizing: border-box;
	background: ${colours.white};
	margin: 0 -30px;

	${media.tablet`
		padding: 30px 0;
		margin: 0 -16px;
		${padding};
		flex-direction: column;
	`}

	${Wrap} {
		flex-basis: 50%;

		${media.tablet`
			flex-basis: 100%;
		`}

		${Title} {
			${type.heading2};
			color: ${colours.black};
			padding-bottom: 28px;
		}

		${Text} {
			p {
				font-size: 24px;
				color: ${colours.black};
				margin: 0;
				padding-bottom: 60px;

				${media.tablet`
					font-size: 18px;	
					padding-bottom: 20px;
				`}
			}
		}

		${List} {
			display: flex;

			${media.tablet`
				flex-direction: column;	
			`}
		}

		${Button} {
			${button};
			color: ${colours.black};
			border-color: ${colours.orange};
			
			${media.tablet`
				border-color: ${colours.orange};	
			`}

			&:hover {
				background: ${colours.orange};
			}
		}

		${BGImage} {
			width: 100%;
			height: 100%;
			min-height: 300px;

			${media.tablet`
				display: none;	
			`}
		}
	}
`

export default Section
