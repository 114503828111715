import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    bgImage,
    button,
    type,
    colours,
} from '../../../styles/global'

const BlockBigButton = (props) => {
    const { text, link } = props

    return (
        <Button as={Link} to={props?.link || '/timetable'}>
            {props?.text || 'Bend with us'}
        </Button>
    )
}

// Wrapper

BlockBigButton.wrapper = css`
    margin-bottom: 0;

    ${media.tablet`
		margin-bottom: 40px;
	`}
`

// Layout

const Button = styled.div`
    display: flex;
    align-items: center;
    color: ${colours.black};
    background-color: ${colours.white};
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
    border-radius: 56px;

    padding: 60px 52px;
    box-sizing: border-box;

    font-size: 93px;
    line-height: 1.1;
    display: inline-block;

    margin-left: 20%;
    margin-top: 80px;
    margin-bottom: 120px;

    ${media.phone`
		font-size: 34px;
		padding: 40px 40px;
		margin-left: 20px;
		margin-top: 20px;
		margin-bottom: 20px;
		border-radius: 24px;
	`}

    &::before {
        content: '';
        display: none;
        background-image: url(${require('../../../assets/svg/arrow-right.svg')});
        width: 90px;
        height: 60px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        padding: 0 4px;
        margin-right: 12px;
    }

    &:hover {
        cursor: pointer;
        background: ${colours.purple};

        &:before {
            display: inline-block;
        }
    }
`

export default BlockBigButton
