import { forEach } from 'lodash';
import { useStaticQuery } from "gatsby";

export const parseACF = (queryData, nodeName, debug) => {    
    if (queryData && queryData[nodeName] ) {
        const nodes = queryData[nodeName].nodes;
        const acfJSON = nodes && nodes[0].acf_json

        if (!acfJSON) return;
        
        // Console log out if debug = true

        debug && console.log(JSON.parse(acfJSON))

        // Return parsed ACF JSON

        return JSON.parse(acfJSON)
    }
}

// Group option page keys by prefix

export const groupByPrefix = (data, prefix) => {
    let items = {}

    forEach(data, (item, key) => {
        if (key.includes(prefix)) {
            items[key.replace(`${prefix}_`, '')] = item;
        }
    })

    return items
}

// Get global data

export const getGlobalData = (prefix) => {
     const query = graphql`
         query {
			allWordpressGlobal {
				nodes {
					acf_json
				}
			}
        }
     `

     // Fetch global data

     const globalData = parseACF(useStaticQuery(query), 'allWordpressGlobal')

     // Return global + grouped by prefix

     return {
         global: globalData,
         data: groupByPrefix(globalData, prefix)
     }
}

// Scroll to ref

export const scrollToRef = (ref) => {
    if (!ref || !ref.current) return;

    try {
        window.scroll({
            top: ref.current.offsetTop,
            left: 0,
            behavior: 'smooth',
        });
    } catch (error) {
        window.scrollTo(ref.current.offsetTop, 0);
    }
}
 
export const scrollToTop = () => {
    try {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    } catch (error) {
        window.scrollTo(0, 0);
    }
}

// Truncate

export const truncateString = (str, num) => {
	
	if (str.length <= num) {
		return str
	}

	return str.slice(0, num) + '...'
}