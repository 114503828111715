import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from "framer-motion";

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

import Block from './BlockFaqItem'

const BlockFaq = (props) => {
	const [expanded, setExpanded] = useState(0)
	const { content, display } = props;

	const renderAccordionItems = () => {
		if (!content) return;
	
		return content.map((item, i) => {  
			return (
				<Block
					key={i}
					{...item}
					expanded={expanded}
					setExpanded={setExpanded}
				/>
			)
		})
	}
        
	return (
		<Accordion>
			{ display && (
				renderAccordionItems()
			)}
		</Accordion>
	)
}

BlockFaq.wrapper = css`
	${media.tablet`
		margin-bottom: 20px;	
	`}
`

const Accordion = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`

export default BlockFaq
