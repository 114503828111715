import React from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const BlockImage = (props) => {
	const { image } = props;
        
	return (
		<Wrap>
			{image && (
				<Image
					key={image?.sizes?.medium2}
					src={image?.sizes?.medium2}
				>
					{(src, loading) => {
						return (
							<BGImage
								image={src}
								style={{ opacity: loading ? 0 : 1 }}
							/>
						)
					}}
				</Image>
			)}
		</Wrap>
	)
}


// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Wrapper

BlockImage.wrapper = css`
	margin: 40px 0 100px;
`

// Layout

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	${BGImage} {
		${bgIcon};
		width: 100%;
		height: 100%;
		max-width: 847px;
		min-height: 590px;

		${media.tablet`
			min-height: 240px;	
		`}
	}
`

export default BlockImage
