import React, { useState, useRef } from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import { Link } from 'gatsby'
import DynamicForm, { useForm } from 'react-dynamic-form'
import { useSetState } from 'react-use'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Input } from '../'

import { media } from '../../styles/utils'
import { container, padding, colours, type } from '../../styles/global'
import { newsletterForm } from './data'

const Newsletter = (props) => {
    const { heading, text, footnote, formStyles } = props
    const [form, { formSubmit, setError }] = useForm()
    const [formComplete, setFormComplete] = useState(false)

    const handleSubmit = (fields, resetForm) => {
        let data = {
            email: fields['email'].value,
        }

        addToMailchimp(data.email, {}).then((mc_resp) => {
            if (mc_resp.result == 'success') {
                setFormComplete(true)
                setError(false)
                console.log('form complete:', formComplete)
            }

            if (mc_resp.result == 'error') {
                setError(mc_resp.msg)
                console.log('form error:', mc_resp.msg)
            }
        })

        resetForm()
    }

    // Form
    const renderForm = () => {
        return (
            <Signup>
                {!formComplete && (
                    <FormWrapper>
                        <DynamicForm
                            form={form}
                            data={newsletterForm}
                            renderInput={<Input />}
                            moveToInvalidField={false}
                            styles={formStyles}
                            renderSubmit={false}
                            onSubmit={handleSubmit}
                        />

                        <Button onClick={formSubmit}>Join</Button>
                    </FormWrapper>
                )}

                {form.error && (
                    <Error
                        dangerouslySetInnerHTML={{
                            __html: form.error,
                        }}
                    />
                )}

                {formComplete && (
                    <ThankYou
                        dangerouslySetInnerHTML={{
                            __html: `
							Thanks for joining! 
						`,
                        }}
                    />
                )}
            </Signup>
        )
    }

    return (
        <Wrapper>
            <Container>
				<Meta>
					<Heading>{heading}</Heading>
					<Text>{text}</Text>
				</Meta>
				<Wrap>
					{renderForm()}
					<Text>{footnote}</Text>
				</Wrap>
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Button = styled.div``
const Text = styled.div``

// Layout

const Wrapper = styled.div`
    width: 100%;
`

const Container = styled.div`
	width: 100%;
	display: flex;
	background: ${colours.lightBlue};
	padding: 40px 32px;
	box-sizing: border-box;
	border-radius: 12px;
	margin-bottom: 24px;

	${media.tablet`
		padding: 20px 20px 24px;
		flex-direction: column;	
	`}
`

// Meta

const Meta = styled.div`
	flex-basis: 40%;
	padding-right: 60px;

    ${Heading} {
		padding-bottom: 18px;

		${media.phone`
			padding-bottom: 20px;
		`}
	}

	${Text} {
		font-size: 21px;

		${media.tablet`
			font-size: 19px;
		`}
	}
`

// Form Wrapper

const Wrap = styled.div`
	flex-basis: 60%;
	padding-top: 8px;

	${Text} {
		font-size: 14px;

		${media.tablet`
			display: none;	
		`}
	}
`

// Form

const Signup = styled.div`
    display: flex;
	flex-direction: column;
	padding-bottom: 30px;

	${media.tablet`
		padding-bottom: 0;
	`}
`

const FormWrapper = styled.div`
    display: flex;
	width: 100%;
	
	${media.tablet`
		padding-top: 20px;	
	`}

    ${Button} {
		color: ${colours.white};
		background: ${colours.black};
		cursor: pointer;
		padding: 8px 24px;
		box-sizing: border-box;
		border-radius: 57px;
		min-width: 154px;
		text-align: center;
		margin-left: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		${media.tablet`
			min-width: auto;
			padding: 8px 32px;
		`}

		&:hover {
			color: ${colours.black};
			background: ${colours.purple};
		}
    }
`

const formStyles = css`
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    width: 100%;

    .dynamic-fields {
        width: 100%;
        display: flex;
        padding-right: 105px;
        border-bottom: 1px solid black;

        ${media.phone`
			flex-direction: column;
			align-items: center;
			padding-right: 0;
		`}
    }

    .dynamic-field {
        margin-bottom: 16px;
        width: 100%;
        height: 42px;

        input {
            width: 100%;
            background: white;
            padding: 0;
            letter-spacing: 0;

            &::placeholder {
                letter-spacing: 0;
            }
        }

        &.email {
            margin-right: 5px;
        }
    }

    .error {
        margin-top: 32px;

        &,
        p,
        a {
            font-size: 16px;
            color: ${colours.black} !important;
        }
    }
`

const ThankYou = styled.div`
    font-size: 24px;
    padding-top: 12px;
`

const Error = styled.div`
    font-size: 16px;
    padding-top: 12px;

    a {
        font-size: 16px;
        color: ${colours.red};
    }
`

export default Newsletter
