import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {
    useWindowScroll,
    useWindowSize,
    useMount,
    useLocation,
} from 'react-use'
import styled, { css } from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'

import { media, useBreakpoint } from '../../styles/utils'
import {
    container,
    bgIcon,
    bgImage,
    padding,
    hoverState,
    buttonSmall,
    colours,
} from '../../styles/global'
import { parseACF } from '../../utils'

import HoiLogo from '../../assets/svg/logo.svg'

import {
    motion,
    useTransform,
    useSpring,
    useViewportScroll,
    transform,
} from 'framer-motion'

const Header = (props) => {
    const { x, y } = useWindowScroll()
    const [active, setActive] = useState(false)
    const [booknow, setBooknow] = useState(false)
    const [scrollComplete, setScrollComplete] = useState(false)
    const [isHome, setHome] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const { width, height } = useWindowSize()
    const { scrollY } = useViewportScroll()
    const detectMobile = useBreakpoint('phone')
    const [showAnnouncement, setShowAnnouncement] = useState(false)

    const data = useStaticQuery(query)
    const menuData = data?.menus?.nodes[0]?.menus?.main_menu || false
    const booknowData = data?.locations?.nodes[0]?.acf?.locations || false
    const announcementData = data?.announcement?.options || false

    const url = useLocation()
    const yRange = useTransform(scrollY, [0, 1000], [480, 160])
    const scale = useSpring(yRange, { stiffness: 100, damping: 30 })

    // Check if Mobile

    useMount(() => {
        setIsMobile(detectMobile)
    })

    useMount(() => {
        setHome(url.pathname == '/')
    }, [isHome])

    useEffect(() => {
        if (announcementData?.announcement_show == true) {
            setShowAnnouncement(true)
        }
    }, [announcementData])

    const renderAnnouncement = () => {
        if (!showAnnouncement) return

        return (
            <Announcement>
                <Text dangerouslySetInnerHTML={{ __html: announcementData?.announcement_content }} />

                <div 
                    onClick={() => {
                        setShowAnnouncement(false)
                    }} 
                    className={'close'}
                >
                    <span className="cross-1" />
                    <span className="cross-2" />
                </div>
            </Announcement>
        )
    }

    // Setup scrolling & logo

    const slugify = (text) => {
        return text
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+/g, '-')
          .replace(/^-|-$/g, '');
      };

    const renderMenu = () => {
        const items = menuData.map((item, i) => {
            const className = slugify(item.title, { lower: true });
            const isExternalLink = item.url === "/locations/lyall-bay/" || item.url === "/locations/miramar/";
            
            let additionalText = null;
            if (item.url === "/locations/lyall-bay/") {
                additionalText = "Personalised training & personal classes ";
            } else if (item.url === "/locations/miramar/") {
                additionalText = "Group reformer classes";
            }

            return (
                <MenuItem 
                    className={className}
                    key={i}
                    as={isExternalLink ? 'a' : Link}
                    href={item.url}
                >
                    {item.title}
                    {additionalText && <SubText>{additionalText}</SubText>}
                </MenuItem>
            )
        });

        return <Menu>{items}</Menu>
    }

    const renderBooknow = () => {
        const sortedItems = booknowData.sort((a, b) => {
            if (a.slug.includes('miramar')) return -1;
            if (b.slug.includes('miramar')) return 1;
            return 0;
        });

        const items = sortedItems.map((item, i) => {
            const href = item.slug.includes('miramar') ? '/locations/miramar#timetable' : '/locations/' + item.slug;

            return (
                <MenuItem 
                    key={i}
                    as={'a'}
                    href={href}
                >
                    {item.title}
                    {item.acf?.listing_text && (
                        <Text dangerouslySetInnerHTML={{ __html: item.acf?.listing_text }} />
                    )}
                </MenuItem>
            )
        })

        return <BookMenu>{items}</BookMenu>
    }

    return (
        <Container hasAnnouncement={showAnnouncement}>
            <Wrapper>
                {renderAnnouncement()}

                <Link to={'/'}>
                    <Logo
                        style={{ width: isHome && !isMobile ? scale : '160px' }}
                        src={HoiLogo}
                    />
                </Link>
            </Wrapper>

            <OutsideClickHandler
                onOutsideClick={() => {
                    setActive(false),
                    setBooknow(false)
                }}
            >
                <Nav>
                    <Button
                        onClick={() => {
                            setBooknow(false)
                            setActive(!active)
                        }}
                    >
                        {active ? 'Close' : 'Menu'}
                    </Button>

                    {active && renderMenu()}

                    <Book 
                        onClick={() => {
                            setActive(false)
                            setBooknow(!booknow)
                        }}
                    >
                        {booknow ? 'Close' : 'Book Now'}
                    </Book>

                    {booknow && renderBooknow()}
                </Nav>
            </OutsideClickHandler>
        </Container>
    )
}

// Shared

const Wrapper = styled.div``
const Icon = styled.div``
const Text = styled.div``

// Layout

const Container = styled.div`
    ${container}
    ${padding}

	width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    padding-top: 24px;
    pointer-events: none;

    transform: translate3d(0, 0, 0);

    ${props => {
		if (props.hasAnnouncement == true) {
            return css`
                ${media.tablet`
                    padding-top: 90px;
                `}
            `
        }
	}}
`

// Logo

const Logo = styled(motion.img)`
    transform-origin: top left;
    pointer-events: all;
    filter: none;
`

// Nav

const Nav = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    pointer-events: all;
`

// Announcement

const Announcement = styled.div`
    font-size: 18px;
    line-height: 1.1;
    color: ${colours.black};
    background: #FDEAD1;
    margin-bottom: 8px;
    text-align: right;
    font-weight: 500;
    padding: 12px 16px 12px 24px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 12%);
    width: fit-content;
    border-radius: 35px;

    display: flex;
    align-items: center;

    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 700px;
    text-align: left;
    pointer-events: all;

    ${media.tablet`
        margin: 12px;
        width: calc(100% - 24px);
        justify-content: space-between;

        top: 0;
        left: 0;
        transform: none;
    `}

    ${Text}{
        a {
            font-size: 18px;
            text-decoration: underline;
    
            ${media.tablet`
                font-size: 14px;
            `}

            &:hover {
                text-decoration: none;
            }
        }
    
        p {
            font-size: 18px;
            margin: 0;   
    
            ${media.tablet`
                font-size: 14px;
            `}
        }
    }

    .close {
        position: relative;
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-left: 12px;

        ${media.tablet`
            width: 24px;
            height: 24px;
        `}

        .cross-1 {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background: ${colours.black};
            transform: rotate(45deg) translateY(-50%);
        }

        .cross-2 {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background: ${colours.black};
            transform: rotate(-45deg) translateY(-50%);
        }
    }
`

// Menu

const Menu = styled.div`
    position: absolute;
    top: 58px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 420px;
    background: ${colours.white};
    border-radius: 22px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    padding: 24px;
    box-sizing: border-box;

    ${media.tablet`
		width: 340px;
	`}
`

const BookMenu = styled.div`
    position: absolute;
    top: 112px;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 420px;
    background: ${colours.white};
    border-radius: 22px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    padding: 24px;
    box-sizing: border-box;

    ${media.tablet`
		width: 340px;
	`}

    ${MenuItem}{
        ${Text}{
            font-size: 14px;
            padding-top: 4px;
        }
    }
`

const SubText = styled.div`
    font-size: 14px;
    color: ${colours.grey};
    margin-top: 4px;
`;

const MenuItem = styled.div`
    color: ${colours.black};

    &.miramar {
        padding: 12px 0;
    }

    &.lyall-bay {
        padding: 0 0 12px 0;
    }

    &[aria-current="page"] {
        color: ${colours.orange};
    }

    &:hover {
        color: ${colours.orange};
    }

    ${media.tablet`
		font-size: 36px;
		line-height: 1.2;
	`}
`

const Button = styled.div`
    ${buttonSmall}
    margin-bottom: 16px;
    width: fit-content;
`

// Book Now

const Book = styled.div`
	${buttonSmall}
	color: ${colours.white};
	background: ${colours.black};
	margin-bottom: 16px;
`

// Profile

const Profile = styled.div`
    ${buttonSmall}
    padding: 12px 14px;
    margin-bottom: 16px;
    width: fit-content;

    ${Icon} {
        ${bgIcon};
        background-image: url(${require('../../assets/svg/profile.svg')});
        width: 14px;
        height: 16px;
        box-sizing: border-box;
    }

    &:hover {
        ${Icon} {
            background-image: url(${require('../../assets/svg/profile-white.svg')});
        }
    }
`

// Data

export const query = graphql`
    query {
        menus: allWordpressGlobal {
            nodes {
                menus {
                    main_menu {
                        title
                        url
                    }
                }
            }
        },
        locations: allWordpressGlobal {
            nodes {
                acf {
                    locations {
                        title
                        slug
                        acf {
                            listing_text
                        }
                    }
                }
            }
        },
        announcement: wordpressAcfOptions {
            options {
                announcement_content
                announcement_show
            }
        }
    }
`

export default Header