import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { resolveSection } from './utils';

const Section = (props) => {
	const { layout, display} = props;
    const SectionLayout = resolveSection(layout);

	if (!SectionLayout || !display) return <></>

	return (
		<Wrapper
			className={`section_${layout}`}
			layout={layout}
			sectionProps={props}
			styles={SectionLayout && SectionLayout.wrapper}
		>
			{SectionLayout && (
				<SectionLayout {...props}/>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
    width: 100%;
	margin-bottom: 60px;
	
	/* Block specific container styles */

	${props => {
		if (props.styles) return props.styles
	}}
`

export default Section