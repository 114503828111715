import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from '../../styles/utils'
import { container, padding, colours, type } from '../../styles/global'

class Input extends Component {
    state = {
        focused: false,
    }

    onBlur = () => {
        const { toggleFocus } = this.props

        this.setState({ focused: false })

        if (toggleFocus) {
            toggleFocus(false)
        }
    }

    onFocus = () => {
        const { toggleFocus } = this.props

        this.setState({ focused: true })

        if (toggleFocus) {
            toggleFocus(true)
        }
    }

    shouldComponentUpdate() {
        // Stop stripe fields from updating

        if (this.props.stripeOptions) {
            return false
        }

        return true
    }

    onChangeValue = (e) => {
        const { validator, onChangeValue } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    shouldShowError = () => {
        const { focused } = this.state
        const { shouldValidate, isValid } = this.props

        if (shouldValidate === true && !isValid) {
            return true
        }

        if (shouldValidate && !isValid && !focused) {
            return true
        }
    }

    getInputProps = () => {
        const {
            value,
            label,
            type,
            placeholder,
            className,
            disabled,
            name,
            locale,
            id,
            icon,
        } = this.props
        const showError = this.shouldShowError()

        return {
            className: className,
            id: name,
            type: type,
            placeholder: placeholder || label,
            value: value || '',
            disabled: disabled,
            hasIcon: icon,
            onChange: !disabled && this.onChangeValue,
            error: showError,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
        }
    }

    render() {
        const { focused } = this.state
        const {
            errorMessage,
            helperText,
            id,
            icon,
            type,
            label,
            validator,
            component,
        } = this.props
        const showError = this.shouldShowError()
        const CustomComponent = component || false

        return (
            <Wrapper
                id={id}
                className={[
                    id,
                    'dynamic-field',
                    showError ? 'field-error' : '',
                ]}
            >
                <FieldWrapper className={'field-wrapper'}>
                    <Field className={'field'} error={showError}>
                        {!CustomComponent && (
                            <>
                                {type == 'textarea' ? (
                                    <TextField {...this.getInputProps()} />
                                ) : (
                                    <InputField {...this.getInputProps()} />
                                )}
                            </>
                        )}

                        {CustomComponent && (
                            <CustomComponent
                                {...this.props}
                                {...this.props.stripeOptions}
                            />
                        )}
                    </Field>

                    {helperText && <HelperText>{helperText}</HelperText>}
                </FieldWrapper>

                {showError && <Error className={'error'}>{errorMessage}</Error>}
            </Wrapper>
        )
    }
}

export const red = '#FF0000'

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`

const FieldWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

// Field

export const inputStyle = css`
    appearance: none;
    box-shadow: none;
    display: flex;
    flex: 1;
    height: 42px;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    background: transparent;
    padding: 0 12px;
    border-radius: 0;

    &,
    &::placeholder {
        ${type.body}
        letter-spacing: 0.2em;
    }

    &::placeholder {
        color: ${colours.black};
    }

    &:hover {
    }

    &:focus {
        outline: none;
    }

    ${(props) => {
        if (props.disabled)
            return css`
                pointer-events: none;

                &,
                &::placeholder {
                    color: rgba(0, 0, 0, 0.2);
                }
            `
    }}

    ${(props) => {
        if (props.hasIcon)
            return css`
                padding-left: 43px;
                padding-bottom: 2px;
            `
    }}
`

const Field = styled.div`
    display: flex;
    width: 100%;
    position: relative;

    select {
        ${inputStyle}
        border-radius: 0;
    }
`

export const InputField = styled.input`
    ${inputStyle}
    min-width: 110px;
`

export const TextField = styled.textarea`
    ${inputStyle}
    height: 270px;
    resize: vertical;
    padding-top: 12px;
    box-sizing: border-box;
`

// Label

const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;

    ${media.phone`
        display: none;
    `}
`

const Label = styled.div`
    font-size: 16px;
    padding-top: 4px;
    color: white;

    ${(props) => {
        if (props.error)
            return css`
                color: ${red};
            `
    }}
`

const message = css`
    font-size: 14px;
`

// Helper Text

export const HelperText = styled.div`
    ${message}
    position: absolute;
    right: -24px;
    max-width: 240px;
    transform: translateX(100%);
`

// Error

export const Error = styled.div`
    ${message}
    color: ${red} !important; 
    margin-top: 8px;
	margin-left: auto;
`

export default Input
