import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    bgImage,
    button,
    type,
    colours,
} from '../../../styles/global'

const BlockMindBody = (props) => {
    const { content } = props

    return <MindBody dangerouslySetInnerHTML={{ __html: content }} />
}

// Wrapper

BlockMindBody.wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

// Layout

const MindBody = styled.div`
    min-height: 780px;
    max-width: 825px;
    width: 100%;
    height: 100%;

    ${media.tablet`
		margin-bottom: 20px;
		min-height: 534px;	
	`}
`

export default BlockMindBody
