import React from 'react'
import { required, password, date, email, requiredBool } from '../../utils'

export const newsletterForm = {
    fields: [
        {
            type: 'email',
            name: 'email',
            label: 'Email address',
            validator: email,
            errorMessage: 'Please enter a valid email',
        },
    ],
}
