import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, button, type, colours } from '../../../styles/global'

import ArrowRight from '../../../assets/svg/arrow-right-white.svg'
import Slack from '../../../assets/svg/social-slack.svg'
import Instagram from '../../../assets/svg/social-instagram.svg'
import Facebook from '../../../assets/svg/social-facebook.svg'
import Spotify from '../../../assets/svg/social-spotify.svg'

const BlockLink = (props) => {
	const { type, link } = props;
        
	return (
		<ListItem type={type} as={'a'} target="_blank" to={link ? link : '#'}>
			<Icon />

			{type == 'instagram' && (
				<Text>Instagram</Text>
			)}

			{type == 'facebook' && (
				<Text>Facebook</Text>
			)}
			<Arrow />
		</ListItem>
	)
}

// Shared

const Text = styled.div``
const Icon = styled.div``
const Arrow = styled.div``

// Wrapper

BlockLink.wrapper = css`
	margin-bottom: 0;
`

// Layout

const ListItem = styled.div`
	display: flex;
	align-items: center;
	background-color: ${colours.black};

	min-width: 180px;
	min-height: 61px;

	box-sizing: border-box;
	border-radius: 22px;
	padding: 0 28px;
	font-size: 36px;
	line-height: 1.1;

	display: flex;
	align-items: center;
	margin-right: 24px;

	${media.phone`
		font-size: 26px;
		padding: 12px 20px;
		display: inline-flex;
		margin-bottom: 12px;
	`}

	&:hover {
		cursor: pointer;
		background: ${colours.purple};
	}

	${Icon} {
		${bgIcon};

		${(props) => {
			if (props.type == 'slack')
				return css`
					background-image: url(${Slack});
					width: 95px;
					height: 25px;
				`
		
			if (props.type == 'spotify')
				return css`
					background-image: url(${Spotify});
					width: 102px;
					height: 30px;
				`
		
			if (props.type == 'instagram')
				return css`
					background-image: url(${Instagram});
				`
		
			if (props.type == 'facebook')
				return css`
					background-image: url(${Facebook});
				`
		}}
	}
	
	${Text}{
		font-size: 21px;
	}

	${Arrow} {
		background-image: url(${ArrowRight});
		width: 18px;
		height: 24px;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		padding: 0 4px;
		margin-left: 12px;
	}
`

export default BlockLink
