import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const BlockSubHeading = (props) => {
	const { content } = props;
        
	return (
		<SubHeading>{content}</SubHeading>
	)
}


// Wrapper

BlockSubHeading.wrapper = css`
	margin: 0;
`

// Layout

const SubHeading = styled.div`
	${type.body};
	padding-bottom: 32px;
	/*padding-left: 42px;*/

	${media.tablet`
		padding-left: 0;
	`}
`

export default BlockSubHeading
