import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import {
    container,
    bgIcon,
    padding,
    hoverState,
    bgImage,
    button,
    type,
    colours,
} from '../../../styles/global'

const BlockCard = (props) => {
    const {
        title,
        meta,
        description,
        price,
        terms,
        useLink,
        link,
        linkText,
        color,
        priceNote,
        subHeading,
    } = props

    return (
        <CardItem background={color} hasButton={useLink}>
            <Header>
                <Title>{title}</Title>
                {subHeading && (
                    <Text dangerouslySetInnerHTML={{ __html: subHeading }} />
                )}
            </Header>
            <Meta>
                <Text dangerouslySetInnerHTML={{ __html: meta }} />
                <Description
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </Meta>
            <Wrap>
                {useLink && (
                    <Button as={'a'} target="_blank" href={link}>
                        {linkText ? linkText : 'Book Now'}
                    </Button>
                )}
                <PriceWrap>
                    <Price>{price}</Price>

                    {priceNote && (
                        <Text dangerouslySetInnerHTML={{ __html: priceNote }} />
                    )}
                </PriceWrap>

                {terms && <Terms>{terms}</Terms>}
            </Wrap>
        </CardItem>
    )
}

// Shared

const Wrap = styled.div``
const Title = styled.div``
const Text = styled.div``
const Description = styled.div``
const Meta = styled.div``
const Price = styled.div``
const Terms = styled.div``
const Button = styled.div``
const Header = styled.div``
const PriceWrap = styled.div``

// Wrapper

BlockCard.wrapper = css`
    margin-bottom: 20px;
    height: auto;
    flex-basis: calc(50% - 12px);
    max-width: calc(50% - 12px);

    ${media.phone`
		flex-basis: 100%;
		max-width: 100%;
	`}
`

// Layout

const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.background || '#E0E0F3'};
    padding: 30px;
    box-sizing: border-box;
    border-radius: 22px;
    margin-bottom: 20px;
    height: 100%;
    transition: 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    ${media.phone`
		padding: 20px;
		flex-basis: 100%;
		max-width: 100%;
	`}

    &:hover {
        background: ${colours.lightBlue};
    }

    ${Header} {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 2px solid ${colours.black};

        ${Title} {
            ${type.body};
            font-family: 'Souvenir', serif;
            padding-bottom: 12px;
    
            ${media.tablet`
                font-size: 30px;
                padding-bottom: 8px;
                margin-bottom: 12px;	
            `}
        }

        ${Text} {
            p, a {
                font-size: 21px;
                margin-right: 20px;
                margin-bottom: 0px;

                ${media.tablet`
                    padding-bottom: 8px;	
                `}
            }
            a {
                text-decoration: underline;
            }
        }
    }

    ${Meta} {
        display: flex;
        justify-content: space-between;
        min-height: 150px;

        ${media.tablet`
			min-height: auto;
			flex-direction: column;	
		`}

        ${Text} {
            flex-basis: calc(45% - 12px);
            font-size: 21px;
            font-weight: 500;
            min-width: 205px;
            max-width: 205px;
            margin-right: 20px;

            ${media.tablet`
				padding-bottom: 20px;	
			`}
        }

        ${Description} {
            p {
                font-size: 21px;

                a {
                    color: inherit;
                    font-size: inherit;
                    text-decoration: underline;
                }
            }
        }
    }

    ${Wrap} {
        display: flex;
        flex-direction: row;
        margin-top: auto;

        ${Button} {
            ${button};
            color: ${colours.black};

            &:hover {
                &:before {
                    display: none;
                }
            }
        }

        ${PriceWrap}{
            display: flex;
            gap: 20px;
            align-items: center;

            ${Price} {
                ${type.body};
                align-self: center;
                margin-left: 24px;
    
                ${(props) => {
                    if (props.hasButton == false)
                        return css`
                            margin-left: 0;
                        `
                }}
            }

            ${Text}{
                font-size: 21px;
            }
        }

        ${Terms} {
            font-size: 21px;
            align-self: center;
            margin-left: 24px;
            padding-top: 12px;

            ${media.tablet`
				${type.bodySmall};
				display: none;	
			`}
        }
    }
`

export default BlockCard
