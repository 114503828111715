import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, button, type, colours } from '../../../styles/global'

import Block from './BlockCard'

const BlockPlan = (props) => {
	const { content, display, plan, acf } = props;
        
	return (
		<Plan>
			{ display && (
				<Block
					title={plan.post_title}
					meta={acf.intro}
					description={acf.short_description}
					price={'$' + acf.price}
					terms={acf.price_note}
					useLink
					link={acf.mindbody_link}
					linkText={acf.link_text}
				/>
			)}
		</Plan>
	)
}

// Shared

const Wrap = styled.div``
const Title = styled.div``
const Text = styled.div``
const Description = styled.div``
const Meta = styled.div``
const Price = styled.div``
const Terms = styled.div``
const Button = styled.div``


// Wrapper

BlockPlan.wrapper = css`
	flex-basis: calc(50% - 12px);
	max-width: calc(50% - 12px);

	${media.phone`
		flex-basis: 100%;
		max-width: 100%;
	`}
`

// Layout

const Plan = styled.div`
	
`

export default BlockPlan
