import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../../../styles/global'

const BlockText = (props) => {
	const { content } = props;
        
	return (
		<Text
			dangerouslySetInnerHTML={{__html: content}}  
		/>
	)
}

// Wrapper

BlockText.wrapper = css`
	width: 50%;

	${media.tablet`
		width: 100%;	
	`}
`

// Layout

const Text = styled.div`
	p, h1, h2, h3, h4 {
		${type.body};
	}

	h5 {
		${type.bodySmall};
		font-weight: 400;
	}

	ul, ol {
		margin: 0;
		padding: 0;
		font-size: 21px;
	}

	li {
		padding-left: 44px;
		// margin-bottom: 30px;
		list-style-type: none;
		// list-style-position: inside;
		position: relative;
		${type.body};

		&:before {
			content: " ";
			position: absolute;
			top: 15px;
			left: 0;
			width: 18px;
			height: 18px;
			background: black;
			border-radius: 50%;
		}
	}
`

export default BlockText
