import { 
    BlockLink,
    BlockBigButton,
    BlockPlan,
    BlockCard,
    BlockHeading,
    BlockSubHeading,
    BlockTextImage,
    BlockText,
    BlockImage,
    BlockFaq,
    BlockMindBody,
    BlockLocation,
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'link': BlockLink,
        'big_button': BlockBigButton,
        'plan': BlockPlan,
        'card': BlockCard,
        'heading': BlockHeading,
        'sub_heading': BlockSubHeading,
        'text_image': BlockTextImage,
        'text': BlockText,
        'single_image': BlockImage,
        'image': BlockImage,
        'faq': BlockFaq,
        'mindbody_embed': BlockMindBody,
        'location': BlockLocation
    }
    
    return blocks[layout]
}


